// socketToken
export function generateGUID () {
  function _p8 (s) {
    const p = (Math.random().toString(16) + '000000000').substr(2, 8)
    return s ? '-' + p.substr(0, 4) + '-' + p.substr(4, 4) : p
  }
  return _p8() + _p8(true) + _p8(true) + _p8() + '-' + new Date().getTime()
}


/**
 * 当前值为null, undefined, NaN, ''时使用默认值
 * @param {*} value 当前值
 * @param {*} defaultValue 默认值, 不提供则为''
 */
export const defaultTo = (value, defaultValue = '') => (value == null || value === '' || Number.isNaN(value)) ? defaultValue : value

// 比较版本号大小
export const compareVersion = (v1, v2) => {
  const v1Parts = (v1 || '').split('.')
  const v2Parts = (v2 || '').split('.')

  for (let i = 0; i < Math.max(v1Parts.length, v2Parts.length); i++) {
    const v1Part = parseInt(v1Parts[i] || '0', 10)
    const v2Part = parseInt(v2Parts[i] || '0', 10)
    if (v1Part > v2Part) {
      return 1
    }
    if (v1Part < v2Part) {
      return -1
    }
  }
  // 如果所有部分都相等，则版本号相同
  return 0
}

export const transformStyle = style => {
  let { fontSize, letterSpacing, borderRadius, padding } = style || {}
  return {
    ...style,
    fontSize: fontSize + 'px',
    letterSpacing: letterSpacing + 'px',
    borderRadius: borderRadius + 'px',
    padding: padding + 'px'
  }
}

export const transformHtml = text => {
  // 将空格转义
  return text.replace(/ /g, '&nbsp;').replace(/>/g, '&gt;').replace(/</g, '&lt;')
}
